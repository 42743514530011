<template>
  <div class="main">
    <article class="pb-1 pt-1">
      <section>
        <h3>Full stack software &amp; hardware developer</h3>
      </section>
    </article>
    <article id="projects" class="project-section">
      <section class="container">
        <!-- <h2>Below are a few examples of my work.</h2> -->
        <b-row class="mb-3 filter-box">
          <h5 class="mr-3">Filter projects</h5>
          <b-form-checkbox-group
            class="check-box"
            v-model="projectFilters.selected"
            name="filter-buttons"
            :options="projectFilters.categories"
            size="md"
            plain
            button-variant="dark"
          >
          </b-form-checkbox-group>
        </b-row>
      </section>
      <section
        class="container"
        v-for="(data, ptype, index) in $store.state.workTypes"
        :key="index"
      >
        <h2 class="p-4">
          <b>{{ data.typeName }}</b>
        </h2>
        <!-- <transition-group
          class="row justify-content-center"
          name="fade"
          tag="div"
        > -->
        <b-card
          v-for="(project, index) in filteredProjects(ptype)"
          :data-aos="get_fade_type(index)"
          :key="index"
          :img-alt="project.title"
          img-top
          tag="article"
          class="project-card"
          @click="load_project(ptype, project)"
        >
          <b-row
            no-gutters
            v-bind:class="{
              'pleft-align': index % 2 == 0,
              'pright-align': index % 2 == 1,
            }"
          >
            <b-col md="6">
              <b-card-img
                :src="loadProjectImg(project.coverImage)"
                alt="Image"
                class="rounded-lg"
              ></b-card-img>
            </b-col>
            <b-col md="6">
              <b-card-body :title="project.title">
                <b-card-text>
                  {{ project.description }} <br /><br />
                  <div class="card-button">
                    <b-button> More </b-button>
                  </div>
                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
        <!-- </transition-group> -->
      </section>
    </article>
  </div>
</template>

<script>
const images = require.context(
  "../assets/project_images",
  true,
  /\.png$|\.jpg$/
);
const categories = ["All", "Hardware", "Software", "Firmware"];
export default {
  data: () => {
    return {
      show: true,
      // projects: projectsData,
      // casestudies: casestudiesData,
      // workTypes: {
      //   projects: { typeName: "Projects", data: projectsData },
      //   casestudies: { typeName: "Case Studies", data: casestudiesData },
      // },
      projectFilters: {
        categories: categories,
        selected: categories,
        showAll: true,
      },
    };
  },
  watch: {
    projectFilters: {
      handler() {
        let selectedLen = this.projectFilters.selected.length;
        let index = this.projectFilters.selected.indexOf("All");

        if (index > -1 && !this.projectFilters.showAll) {
          this.projectFilters.selected = categories.slice();
          this.projectFilters.showAll = true;
        } else if (selectedLen == categories.length - 1) {
          if (index > -1) {
            this.projectFilters.showAll = false;
            this.projectFilters.selected.splice(index, 1);
          } else {
            this.projectFilters.showAll = true;

            this.projectFilters.selected.push("All");
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    filteredProjects(ptype) {
      return this.$store.state.workTypes[ptype].data.filter((project) => {
        return this.projectFilters.selected.some((c) => {
          return project.category.includes(c);
        });
      });
    },
    loadProjectImg(imgPath) {
      return images("./" + imgPath);
    },
    load_project(ptype, project) {
      this.$router.push({
        path: ptype + "/" + project.name,
      });
    },
    refresh() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
      return;
    },
    get_fade_type(index) {
      if (index % 2) {
        return "flip-up";
      }
      return "flip-down";
    },
  },
};
</script>
<style lang="scss">
.main {
  background: #02011a;
  color: #ffc107;
  h3,
  h4 {
    color: white;
  }
  .card {
    border-radius: 1rem;
  }

  .project-card {
    max-width: 80rem;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 1.5rem;

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
    background: rgba(255, 255, 255, 0.1);
  }
  .pleft-align,
  .pright-align {
    display: flex;
  }
  .pleft-align {
    justify-content: left;
  }
  .pright-align {
    flex-direction: row-reverse;
    justify-items: right;
  }
}
.filter-box {
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(68, 68, 68, 0.288);
  background: rgba($color: #777777, $alpha: 0.25);
  border-radius: 1rem;
}
.project-section {
  padding: 60px 10px;
  background: linear-gradient(to top, #333f61, #333f614f, #02011a),
    url("./../assets/headerbackground4.jpg") no-repeat top center;
  background-size: cover;

  color: rgb(255, 255, 255);
  h3,
  h2 {
    color: #ffc107;
  }
}
.card-body,
.card-title {
  color: rgb(255, 255, 255);

  h4 {
    color: white;
    font-weight: 500;
  }
}
.card-body {
  height: 100%;
  // display: flex;
  // justify-content: space-between;
  // flex-direction: column-reverse;
  .card-button {
    bottom: 1rem;
    button {
      padding-left: 2rem;
      padding-right: 2rem;
      border-radius: 2rem;
      border: 1 solid white;
      border-color: white;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
.filter-button {
  margin-left: 1rem;
}
.left-align {
  display: flex;
  justify-content: left;
}
.right-align {
  display: flex;
  flex-direction: row-reverse;
  justify-items: right;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
// @media screen and (max-width: 500px) {
//   .filter-box {
//     font-size: 1rem;
//   }
// }
// @media screen and (min-width: 900px) {
//   .filter-box {
//     font-size: 2 rem;
//   }
//}
</style>
